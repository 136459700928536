

.ProductOverviewDiv {
    background: #F2F2F2;
    margin-top: 4em;
}
.productErrorStyles {
   color: red;
   text-align: center;
   font-weight: 700;
   font-size: 15px;
   margin-bottom: 1em;
}
.productSuccessStyles {
    color: green;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 1em;
 }
.beforePurchase {
    padding: 20px;
}
.productDetailsBox {
    width: 95%;
    height: 130px;
    padding: 10px;
    border-radius: 12px;
    background: #40637D;
    box-shadow: 0px 8px 28px 0px rgba(16, 24, 40, 0.08);
    pointer-events: none;
}
.ProductDetailsNameTagSetup {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.IconSetup {
    width: 34px;
    height: 34px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 3px solid #FACF39;
    padding: 5px;
}
.IconSetImage {
    width: 18px;
    height: 18px;
}

.productCaption {
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.productCaption::first-letter {
    text-transform: capitalize;
}
.productDescription {
    display: inline;
    padding: 2px 10px;
    border-radius: 24px;
    background: var(--primary-100, #E0E7FF);
    color: var(--primary-600, #4F46E5);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.productSellername {
    color: #1E2D39;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin: 0;
}
.productSellerdesc {
    color: var(--Neutral-500, #737373);
    text-align: justify;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    white-space: nowrap;       /* Prevents the text from wrapping to a new line */
    overflow: hidden;          /* Hides the overflowing text */
    text-overflow: ellipsis;   /* Adds the ellipsis ('...') */
}
.ProductSellerBtns {
    border-radius: 24px;
    background: #E6ECF3;
    border-color: #E6ECF3;
    display: inline-flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #2F48FB;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

}
.lockedImage {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}
.ProductSellerUnlockBtns {
    border-radius: 24px;
    background: none;
    border: none;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #DC2626;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

}
.ProductBuynowBtn {
    padding: 6px 14px;
    background: #FFF;
    color: #1E2D39;
    box-shadow: 0px 2px 4px 1px #2F48FB inset;
    border: none;
    border-radius: 50px 50px 0px 0px;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.ProductAddCartBtns {
    padding: 6px 14px;
    background: #FFF;
    color: #1E2D39;
    box-shadow: 0px 2px 4px 1px #FACF39 inset;
    border: none;
    border-radius: 50px 50px 0px 0px;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.ProductBoxLabel {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}
.ProductBoxlabelValue {
    color: #FACF39;
    font-family: "Josefin Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.accordionHeader {
    border: none;
    background: none;
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}
.accordionHeader .accordion-button {
    background-color: #f0f0f0;
    color: #333;
}
.accordionDescription {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.ProductImageBox {
    border-radius: 6px;
    width: 100%;
    height: 350px;
}
.productContainerdiv {
    position: relative;
    margin: 0 auto;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
}
.productContainerdiv .productContent {
    position: absolute;
    bottom: 0;
    background: transparent;
    color: #f1f1f1;
    width: 100%;
}
.productContent .cartBoxdiv {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
.productContent .buyBoxdiv {
    fill: #FFF;
    
}
.cartBoxMargin {
    margin-right: 2rem;
    gap: 15px;
}
.innerIconImg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}
.toggleDropdowndiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
}
.toggleDropdownClick {
    background: transparent;
    color: #1E2D39;
    outline: none;
    border: none;
    padding: 0px 20px;
}
.toggleToastStyles {
    width: 90%;
    max-width: 90%;
}
.marquee_container {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.marquee_content {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    /* animation: marquee 10s linear infinite; */
    color: #0093ED !important;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
}
@keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    50% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
}


.reviewerName {
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
}
.reviewerCmt {
    color: #666;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px;
    margin-bottom: 0;
}
.reviewContentImg {
    width: 400px;
    height: 250px;
    flex-shrink: 0;
    border-radius: 6px;
}
.ratingPosition {
    position: absolute;
    font-size: 1.2em;
    margin-top: 10em;
    margin-left: 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background: var(--generic-white, #FFF);
    box-shadow: 0 2px 30px #2F48FB;
    color: var(--neutral-900, #171717);
    padding: 2px 10px;
    border-radius: 20px;
}


.feedContainerdiv {
    position: relative;
    margin: 0 auto;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    border: 1px solid rgba(16, 24, 40, 0.10);
    border-radius: 12px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
 .feedThumbnail {
    border: 1px solid #FFF;
    padding: 1rem;
    border-radius: 25px !important;
    width: 100%;
 }
.feedContainerdiv .feedContent {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* background: transparent; */
    color: #f1f1f1;
    width: 100%;
    padding: 2px 10px;
    border-radius: 0px 0px 12px 12px;
}
.feedContainerdiv .feedContentSide {
    position: absolute;
    bottom: 0.2rem;
    right: 2rem;
    background-color: transparent;
    color: #000;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}
.feedContainerdiv .feedUserProfilediv {
    background: transparent;
}
.feedContainerdiv .feedContentdiv {
    border-radius: 60px;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 4px 0px rgba(250, 207, 57, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
}
.feedIconwithCountsdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.feedUserImg {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    border: 4px solid #2F48FB;
    border-image: fill;
}
.feedIconImg {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

.feedIconFollowAdddiv {
    display: contents;
}
.feedIconFollowImg {
    width: 55px;
    height: 55px;
    border-radius: 400px;
    border: 5px solid #FFF;
    position: relative;
}
.feedIconFollowAddIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 40px;
    left: 18px;
}
.feedUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.reviewThumbnail {
    border-radius: 25px !important;
    padding: 15px;
    width: 100%;
    min-height: 600px;
    max-height: 650px;
 }
 .feedContentCommentText {
    color: #FACF39;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 14px;
    margin-bottom: 0;
    margin-top: 0.5em;
 }
.feedContentText {
    width: 80%;
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}

.boastIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.boastIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
}
.boastUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.boastLoadContent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Required for the pseudo-element to be positioned correctly */
    z-index: 1;
}

.CartLoadContent::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/public/Content/Images/Svgs/logo_whitebackground.svg') no-repeat center center; 
    background-size: 25%;
    opacity: 0.5;
    z-index: 10;
}

/* fire animation */
@keyframes fireAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(2.2); }
    100% { transform: scale(1); }
  }
  
  @keyframes disfireAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(0.8); }
    100% { transform: scale(1); }
  }
  
  .animateFire {
    animation: fireAnimation 1.5s ease-in-out;
  }
  
  .animateDisfire {
    animation: disfireAnimation 0.5s ease-in-out;
  }
/* fire animation */





/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .responsiveDisplay {
      display: none;
    }
    .productDetailsBox {
        width: 95%;
        height: 110px;
        padding: 8px;
        border-radius: 12px;
    }
    .IconSetImage {
        width: 14px;
        height: 14px;
    }
    .IconSetup {
        width: 25px;
        height: 25px;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 2px solid #FACF39;
        padding: 4px;
    }
    .ProductBoxlabelValue {
        color: #FACF39;
        font-family: "Josefin Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    .ProductImageBox {
        border-radius: 6px;
        width: 100%;
        height: 180px;
    }
    .beforePurchase {
        padding: 0;
    }
  }
  /* end of Mobile Screens */
  
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {
  
  }
  /* end of Tablets and Larger Screens */