/* hub.module.css 

Author     : Manoj M 
Created on : 14-11-2023  */


.mainPad {
    padding: 0em 2em;
}
.ActionHeader {
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.56px;
    margin-bottom: 15px;
}
.boastLoadContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 2em;
  margin-bottom: 2em;
}
.ActionHeaderM {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.workshopsCountBg {
    background-color: #FACF39 !important;
    color: #000;
}

.HubDashboardDiv {
    background: #F2F2F2;
    margin-top: 4em;
}

.UserfontSize {
    font-size: 2em;
}

.PurchasedExperiencesRow {
    background-color: white;
    padding: 1em 1em;
    border-radius: 12px;
}

.ExpHubItemContainer {
    font-family: system-ui;
}
.workshopContentDiv {
  padding: 0 1rem;
}

.ExpHubItem_TitleFlex {
    display: flex;
    gap: 20px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.ExpHubItem_hubStream {
    width: 100%;
}

.ExpHubItem_Title{
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.ExpHubItem_Title::first-letter{
    text-transform: capitalize;
}
.ExpHubItem_TitleBadge {
    background: #abbbef;
    padding: 0rem 1rem;
    border-radius: 20px;
    font-size: 0.8rem;
    color: #714bff;
}

.ExpHubItem_ProfileCont{
    display: flex;
    justify-content: space-between;
}

.ExpHubItem_ProfileImg{
    border-radius: 50%;
    width: 48px;
    height: 48px;
    border-radius: 1000px;
    border: 2px solid var(--Generic-White, #FFF);

}

.ExpHubItem_ProfileImgCont{
    height: 30px;
    width: 30px;
}
.ExpHubItem_ChatImg {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.ExpHubItem_ProfileLeft{
    display: flex;
    gap: 0.5rem;
    /* font-weight: 700;
    color: #714bff;
    align-items: center; */
}
.workshop_sellername {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.workshop_sellerdesc {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ExpHubItem_ProfileRight{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.ExpHubItem_ProfileBtn{
    background: #E6ECF3;
    border-radius: 24px;
    display: inline-flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #2F48FB;
    text-align: center;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.ExpHubItem_ProfileChatBtn {
    background: transparent;
    display: inline-flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    border-radius: 24px;
    color: #0EB65B;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
.ExpHubItem_ProfileChatBtn:hover {
    background: #40637D;
    color: #FFF;
    padding: 3px 10px;
}
.ExpHubItem_WatchPartyBtn {
  background: #2E637D;
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  border-radius: 24px;
  color: #FFFFFF;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.ExpHubItem_WatchPartyBtn:hover {
  background: #40637D;
  color: #FFF;
  padding: 5px 10px;
}
.ExpHubItem_VideoCont {
    width: 100%;
    margin-top: 1rem;
    border-radius: 24px;
}

.ExpHubItem_DescCont{
    margin: 2rem 0rem;
}

.ExpHubItem_DescTitle{
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}
.ExpHubItem_DescText{
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.ExpHubItem_ExtraMaterialCont{
    margin: 2rem 0rem;
}

.ExpHubItem_ExtraMaterialTitle{
    font-size: 1.2rem;
    color: #0093ED;
}

.HrLine{
    margin: 1rem 2rem;
    border-top: solid 1px lightgray;
}

.RightCont{
    background: white;
    border-radius: 0.5rem;
    height: 80vh;
    position: sticky;
    top: 90px;
}

.RightTitle_Cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.RightMessTitle{
    font-weight: 500;
}

.CloseBtnImg{
    height: 10px;
    width: 10px;
}

.Profileleft{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.ProfileTop{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ProfileImg{
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.PRImage {
    width: 95px;
    height: 60px;
    border-radius: 12px;
    margin-right: 1em;
    font-size: 12px;
}
.MoreBtn{
    height: 15px;
    width: 5px;
}

.circleoverlap{
    display: flex;
    position: relative;
}

.circleoverlap img {
    width: 25px;
    border-radius: 50%;
    border: solid white 2px; 
    margin-right: 2px;
    position: relative;
    background: teal;
  }
  
  .circleoverlapText {
    position: absolute;
    width: 100%;
    font-size: 0.75rem;
  }

  .ChatItemCont{
    padding: 1rem;
    display: flex;
    gap: 5px;
  }

  .ChatItemContRev{
    padding: 1rem;
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
  }

  .ProfileChatName{
    font-size: 1rem;
    font-weight: 500;
  }

  .ProfileChatTime{
    margin-left: 0.5rem;
    font-size: 0.6rem;
  }

  .ProfileChatText{
    font-size: 0.8rem;
    font-weight: 400;
    color: rgb(134, 134, 134);
  }

  .Exp_reactplayer {
    width: 100% !important;
    height: 400px !important;
    border: none;
    border-radius: 12px;
  }

  .Exp_reactplayer > video{
    border-radius: 12px;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    background-color: #000;
  }

  .carousel-container {
    overflow: hidden;
  }
  
  .carousel-wrapper {
    display: flex;
  }
  
  .carousel-slide {
    flex: 0 0 auto;
    width: 100%;
    transition: transform 0.5s ease;
  }
  
  .carousel-slide img {
    width: 100%;
    height: auto;
  }
  .overlayButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .overlayButton:first-child {
    left: 10px;
  }
  
  .overlayButton:last-child {
    right: 10px;
  }

.sendImgBtn{
    background: #714bff;
    border: solid #714bff;
    border-radius: 0.5rem;
    margin: 3px;
    width: calc(20% - 7px);
    height: 40px;
  }
.sendImg{
    height: 15px;
}

.sendInput{
    width: 80%;
    height: 40px;
    border-radius: 5px;
    border: solid lightgray;
    padding: 10px;
}

.sendTextCont{
    width: 100%;
    padding: 1rem;
    position: absolute;
    bottom: 1rem;
}

.errorMessage {
    color: red;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.noProductdata {
    color: red;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
}
  .successMessage {
    background: #EEF2FF;
    color: #6366F1;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 1em;
  }

  .addFeedbackBtn {
    background-color: #FACF39 !important;
    border: none;
    border-radius: 25px;
    padding: 4px 12px;
    color: #000;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    box-shadow: 2px 2px 45px 2px rgba(0, 0, 0, 0.96);
  }

  .addFeedbackBtn:hover {
    background-color: #FFF !important;
    color: #000 !important;
    border: 0.1em solid var(--neutral-200, #40637D);
    padding: 4px 12px;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .toggleDropdowndiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
  }
  .toggleDropdownClick {
    background: transparent;
    color: #1E2D39;
    outline: none;
    border: none;
    padding: 0px 20px;
  }
  .toggleToastStyles {
    width: 90%;
    max-width: 90%;
  }
  .addFeedbackBtn:hover {
    color: #1E2D39;
    background: transparent;
    border: none;
    border-radius: 24px;
    text-decoration: underline;
  }
  .dialogClass {
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buyerReviewHeader {
    color: var(--Neutral-900, #171717);
    text-align: center;
    font-family: "Josefin Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 0.5rem;
  }
  .buyerReviewContent {
    color: var(--Neutral-500, #737373);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .uploadReviewBtn {
    background: var(--primary-100, #E0E7FF);
    color: var(--primary-600, #1E2D39);
    text-align: center !important;
    font-family: "Open Sans" !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    border: none;
    padding: 2px 10px;
    border-radius: 24px;
  }
  .buyerReviewSubmit {
    background: var(--primary-500-main, #2E637D);
    padding: 12px 20px;
    border-radius: 6px;
    color: var(--generic-white, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .buyerReviewFormControl {
    border-radius: 6px;
    border: 1px solid var(--neutral-200, #E5E5E5);
    background: var(--generic-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    padding: 10px;
    resize: none;
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
.hubWorkshopLinkDiv {
    width: 220px;
    white-space: nowrap;
    text-overflow: ellipsis;
    contain: content;
}


/* YourStyles.module.css */

/* Custom CSS for ReactPlayer controls background */

.customCarousel .carousel .control-dots {
  top: 10px; /* Adjust as needed to position at the top */
  bottom: auto;
}

.customCarousel .carousel .control-dots .dot {
  background: #fff; /* Customize the color */
}

.customCarousel .carousel .control-dots .dot.selected {
  background: #000; /* Customize the color */
}

/* Fixing arrow buttons */
.arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #FACF39;
  color: #171717;
  border: none;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;

}

.arrowLeft {
  left: 10px;
}

.arrowRight {
  right: 10px;
}

.arrowLeft:hover, .arrowRight:hover {
  background: #1E2D39; /* Hover background color */
  color: #FFF;
}

/* Custom CSS for ReactPlayer controls background */




/* customvideoplayer */

.VideoPlayerContainer {
  position: relative;
}

.Exp_reactplayer {
  width: 100%;
  height: auto;
}

.ControlBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: white;
}

.Progress {
  display: flex;
  align-items: center;
}

.Progress input[type="range"] {
  margin: 0 10px;
  flex-grow: 1;
}

.Controls {
  display: flex;
  align-items: center;
}

.Controls button {
  margin: 0 5px;
}

.Options {
  display: flex;
  align-items: center;
}

.Options button {
  margin: 0 5px;
}

/* Workshop Thumbnail Styles */

.PurchasedContainer {
  padding: 0;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
  margin-bottom: 1em;
}
.PWContainerdiv {
  position: relative;
  margin: 0 auto;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
  border-radius: 12px;
}
.PWThumbnail {
  border-radius: 12px !important;
  width: 100%;
  max-width: 100%;
  min-height: 640px;
  max-height: 650px;
}
.PWDescTextImgSize {
  width: 20px;
  height: 20px;
  color: #FFF;
}
.PWContainerdiv .PWContent {
  position: absolute;
  bottom: 1.5em;
  border-radius: 0px 0px 12px 12px;
  background: rgba(255, 255, 255, 0.70);
  background: transparent;
  width: 100%;
  padding: var(--Spacing-3, 10px 15px);
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.PWContainerdiv .PWContentTextColorFont {
  width: 75%;
  color: #2F485B;
  color: #FFF;
  text-shadow: 0px 4px 1000px rgba(233, 220, 220, 0.96);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  text-transform: inherit;
  cursor: pointer;
  
  /* To limit content to 3 lines with ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 1.2em;       /* Set desired line-height */
  max-height: 3.6em;

}

.PWContainerdiv .PWContentSide {
  position: absolute;
  bottom: 0rem;
  right: 1rem;
  background-color: transparent;
  color: #000;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}
.PWContainerdiv .PWUserProfilediv {
  background: transparent;
}
.PWContainerdiv .PWContentdiv {
  border-radius: 60px;
  background: rgba(255, 255, 255, 0.88);
  box-shadow: 0px 4px 4px 0px rgba(250, 207, 57, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.3rem;
  padding: 4px;
}
.PWContentText {
  width: 80%;
  color: #FFF;
  text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 
}
.PWIconwithCountsdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.boastPostWorkshopThumbnail {
  width: 60px;
  height: 60px;
  border-radius: 1000px;
  border: 4px solid #2F48FB;
  border-image: fill;
  transition: transform 0.3s ease; /* Add smooth transition */
}
.PWUserImg {
  width: 60px;
  height: 60px;
  border-radius: 1000px;
  border: 4px solid #2F48FB;
  border-image: fill;
  transition: transform 0.3s ease; /* Add smooth transition */
}
.PWIconImg {
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add smooth transition */
}
.PWIconImg:hover, .PWUserImg:hover {
  transform: scale(1.5); /* Scale the image */
}

.boastIconwithCountsdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
}
.boastIconImg {
  width: 32px;
  height: 32px;
  cursor: pointer;
  flex-shrink: 0;
  transition: transform 0.3s ease; /* Add smooth transition */
}

.boastIconImg:hover {
  transform: scale(1.8); /* Scale the image */
}
.boastUserContentFont {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.PWIconFollowAdddiv {
  display: contents;
}
.PWIconFollowImg {
  width: 55px;
  height: 55px;
  border-radius: 400px;
  border: 5px solid #FFF;
  position: relative;
}
.PWIconFollowAddButton {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin: 0;
  outline: none;
}
.PWIconFollowAddButton:hover {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin: 0;
  outline: none;
}
.PWIconFollowAddIcon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 2.6rem;
  left: 1.0rem;
  transition: transform 0.3s ease; /* Add smooth transition */
}
.PWIconFollowAddIcon:hover {
  transform: scale(1.5); /* Scale the image */
}
.PWUserContentFont {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

/* Workshop Thumbnail Styles */

.noPurchasesContainer {
  text-align: center;
  padding: 1em 0em;
  font-size: 1.1em;
  font-weight: bold;
  background: #FFF;
  border-radius: 12px;
}

.createInvirationButtonRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
}
.createButtonStyle {
  background-color: #40637D;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border-radius: 25px;
  border: none;
  padding: 2px 10px;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10); 
}
.joinButtonStyle {
  background-color: #0EB65B;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border-radius: 25px;
  border: none;
  padding: 2px 10px;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10); 
}







/* Invitations Styles */

.createInvirationButtonRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.createButtonStyle {
  background-color: #1E2D39;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  border: none;
  padding: 0px 10px;
}


.newInvitationsBackdrop {
  padding: 2em 2em;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);   
}
.ESWhiteBackdrop p {
  display: flex;  
  align-items: center;
  justify-content: center;
  gap: 10px;

}
.ESWhiteBackdrop .btn {
  color: var(--Generic-white, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.invitesActionHeaderStyle {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 15px;
}


.customTabHeaders {
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10); 
  border-radius: 8px;
  font-weight: 600;
}
.customTabHeader {
  color: #000;
  font-weight: 600;
}
.customeTabClickActive {
  border: none;
  color: #2F48FB;
  border-bottom: 0.2em solid #2F48FB;
  font-weight: 800;
  font-size: 18px;
  border-color: none !important;
  bottom: 1em;
  padding: 10px 0px;
}

/* Invitations Styles */





/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
  .responsiveDisplay {
    display: none;
  }
  .PRImage {
    width: 50px;
    height: 40px;
    align-self: start;
    object-fit: contain;
    border-radius: 12px;
    margin-right: 1em;
    font-size: 12px;
  }
  .hubWorkshopLinkDiv {
    width: auto;
    white-space: normal;

  }
  .PECaption {
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
    width: auto;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

}
.PEDesc {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 1.2em;
    width: auto;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 1.5em; /* Adjust this value based on your font size */
    max-height: 3em; /* 2 lines * line-height (1.5em) */
    position: relative;
}

.PECaption::after, .PEDesc::after {
  position: absolute;
  bottom: 0;
  right: 0;
  background: white; /* Adjust based on your background */
}

.Exp_reactplayer {
  width: 100% !important;
  height: 180px !important;
  border: none;
  border-radius: 12px;
}

}
/* end of Mobile Screens */




/* Media Queries for Tablets and Larger Screens */
@media (min-width: 768px) and (max-width: 991px) {

}
/* end of Tablets and Larger Screens */