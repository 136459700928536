
.profileSectionVP {
    min-height: 80vh;
    max-height: 100vh;
}
.UploadProfileImage {
    width: 34.945px;
    height: 34.945px;
    flex-shrink: 0;
}
.editIconImage {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    position: absolute;
    margin-right: 5px;
}
.contentShowImage {
    width: 20px;
    height: 20px;
    margin-left: 0.5em;
    margin-top: 2.5em;
}
.eyeIconDiv {
    position: absolute;
    margin-right: 2em;
    color: #40637d;
}
.UploadProfileImagediv {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    fill: #FFF;
    filter: drop-shadow(0px 4px 40px #2F48FB);
    background: #FFF;
    border-radius: 50px;
    margin-top: -15%;
}
.UploadProfileRow {
    width: 388px;
    height: 100px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 6px;
    background: rgba(230, 236, 243, 0.50);
}
.tagA {
    color: #2F48FB;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
}
.successProfileModal {
    color: #0EB65B;
    text-align: center;
    font-family: "Josefin Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
}
.failureProfileModal {
    color: red;
    text-align: center;
    font-family: "Josefin Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
}
.successProfileModalMsg {
    color: var(--Neutral-500, #737373);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.NormalText {
    color: var(--Neutral-900, #171717);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400; 
}
.UploadedImage {
    width: 99px;
    height: 99px;
}
.dialogClass {
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profileFormLabel {
    color: #1E2D39;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0.2rem;
}
.profileFormControl {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #E6ECF3;
    background: var(--Generic-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

/* Interest selection styles */
.interestItemOverflow {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
}

.interestItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 20%;
    height: 15vh;
    overflow: hidden;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    margin-bottom: 1em;
    margin-top: 1em;
}
.interestItemName, .interestItem label {
    color: #40637D;
    text-align: center;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: break-spaces;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;

}
.selected label {
    position: relative;
    color: #FACF39 !important;
    font-size: 14px !important;
    /* background-color: #40637D !important; */
    border-radius: 50px;
    padding: 0.6rem 1.0rem;
    background: url('/public/Content/Images/icons/interests_check.png') no-repeat;
    background-position: center calc(100% + 5px);

}

.selected::after {
    content: '';
    position: absolute;
    top: 2rem;
    left: 3rem;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 1.5em;
    height: 1.5em;
    background: url('/public/Content/Images/icons/icons-tick.png') no-repeat center bottom;
    background-size: contain;
}
/* Interest selection styles */


.profileformPhoneInput {
    font-family: 'Open Sans' !important;
    font-size: 16px !important;
    border: 1px solid #dee2e6;
    border-radius: 5px;
  }
.usernameUpdateButton {
    display: none;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #2E637D;
    color: var(--Generic-White, #FFF);
    font-weight: 600;
}

.countrySelectform {
    width: 40%;
}

.stripeDashboardLink {
    color: #2E637D !important;
    font-weight: 600;
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer !important;
    font-size: 16px;
    text-align: center;
    background: none;
    border: none;
    outline: none;
}
