

.educationCaption {
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.educationSubCaption {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.descHeader {
    border: none;
    background: none;
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}

.descDescription {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}
.backeducationDiv {
    display: inline-flex;
    align-items: last baseline;
    gap: 0;
}
.backeducation {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 15px;
}
.backeducationlogo {
    width: 67px;
    height: 67px;
    flex-shrink: 0;
}
.ListGroupStyles {
    border: none;
}
